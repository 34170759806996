<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="preview-header">
                <div class="preview-title">{{ title }}</div>
                <div class="preview-action">
                    <div><download-button dom="#previewContent" :name="this.entity.invoiceCode + '.pdf'"/></div>
                    <div class="preview-print">
                        <a @click="print">Print</a>
                    </div>
                    <div class="preview-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
            </div>
            <md-content id="previewContent">
                <div class="preview-content" style="width: 780px;">
                    <div style="display: flex; border: 1px solid #ccc; padding: 10px;">
                        <div style="width: 50%; display: flex; align-items: center;">
                            <img src="../../../assets/images/logo.png" style="width: 130px; height: 50px;"/>
                        </div>
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <div style="font-weight: bold; font-size: 22px;">QUYẾT TOÁN ĐĂNG KIỂM</div>
                            <div style="font-size: 14px;"><span>Số: </span><span style="font-weight: bold;">{{entity.invoiceCode}}</span></div>
                            <div><span>Ngày: </span><span>{{entity.invoiceDate}}</span></div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center; padding: 10px 0;"> 
                        <span style="font-weight: bold; font-size: 18px;">Kính gửi: </span>
                        <span style="font-weight: bold; font-size: 18px; padding-left: 10px;">BGĐ Công ty PPL</span>
                    </div>
                    <div style="display: flex; flex-direction: column; padding: 10px 0;"> 
                        <p style="padding-bottom: 10px; font-size: 14px;">Tôi tên là: <span style="font-weight: bold; font-size: 15px;">{{entity.staff.fullName}}</span></p>
                        <p style="padding-bottom: 10px; font-size: 14px;">Phòng/Đơn vị công tác: <span style="font-weight: bold; font-size: 15px;">{{entity.dept.name}}</span></p>
                        <p style="padding-bottom: 10px; font-size: 14px;">Đề nghị quyết toán Chi phí đăng kiểm xe <span style="font-weight: bold; font-size: 15px;">{{entity.vehicle.licensePlate}}</span></p>
                    </div>
                    
                    <div>
                        <table style=" border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                            <thead>
                                <tr style="background-color: antiquewhite; font-weight: bold; font-size: 13px;">
                                    <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">STT</th>
                                    <th style="width:250px; padding: 5px 10px; border: 1px solid #e0e0e0;">TÊN PHÍ</th>
                                    <th style="width:200px; padding: 5px 10px; border: 1px solid #e0e0e0;">SỐ TIỀN</th>
                                    <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">VAT</th>
                                    <th style="width:200px; padding: 5px 10px; border: 1px solid #e0e0e0;">THÀNH TIỀN</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="font-weight: bold;">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">I</td>
                                    <td colspan="3" style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">Dự toán số {{ entity.estimate.estimateCode }} ngày {{ entity.estimate.estimateDate }}</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.estimate.pay)}}</td>
                                </tr>
                                <tr style="font-weight: bold;">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">II</td>
                                    <td v-if="entity.advancePayment.pay > 0" colspan="3" style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">Tạm ứng số {{ entity.advancePayment.advancePaymentCode }} ngày {{ entity.advancePayment.advancePaymentDate }}</td>
                                    <td v-else colspan="3" style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">Tạm ứng</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.advancePayment.pay)}}</td>
                                </tr>
                                <tr style="font-weight: bold;">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">III</td>
                                    <td colspan="3" style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">Chi tiết quyết toán</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.pay)}}</td>
                                </tr>
                                <tr v-for="(item, i) in entity.details" :key="item.id">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">{{i + 1}}</td>
                                    <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 40px;">{{item.fee.feeName}}</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(item.amount)}}</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(item.vat)}}%</td>
                                    <td style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(item.pay)}}</td>
                                </tr>
                                <tr style="font-weight: bold;">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">IV</td>
                                    <td colspan="3" style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">Số chi không hết nộp lại Công ty ( II-III )</td>
                                    <td v-if="entity.refund > 0" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.refund)}}</td>
                                    <td v-else style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">0</td>
                                </tr>
                                <tr style="font-weight: bold;">
                                    <td style="text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">V</td>
                                    <td colspan="3" style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">Số chi vượt dự toán/tạm ứng đề nghị chi thêm (III – II)</td>
                                    <td v-if="entity.extend > 0" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.extend)}}</td>
                                    <td v-else style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content: space-around; padding-top: 80px;"> 
                        <div v-for="item in entity.approveList" :key="item.id" style="margin-bottom: 50px; padding-bottom: 50px;">
                            <div v-show="!item.isCommenter" style="display: flex; flex-direction: column; justify-content: center; ">
                                <div style="font-size: 15px; font-weight: bold;">{{item.title}}</div>
                                <div style="font-size: 15px; font-weight: bold; padding-top: 70px;">{{item.staff.fullName}}</div>
                                <div>{{item.note}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-content>
    </md-dialog>
</template>

<script>
    import downloadButton from '../../../components/DownloadButton.vue';

    export default ({
        components: {
            downloadButton
        },
        metaInfo: {
            title: 'Quyết toán toán đăng kiểm'
        },
        data() {
            return {
               title: 'Quyết toán đăng kiểm',
               id: 0,
               showDialog: false,
               loadding: false,
               details: [],
               entity: { id: 0, files: [], approveList: [], details: [], staffId: '', staff: {}, invoiceCode: '', invoiceDate: null, status: 0, amount: 0, vat: 0, pay: 0 },
            }
        },
        methods: {
            async open(obj){
                this.entity = obj;
                this.showDialog = true;
            },

            print () {
                this.$htmlToPaper('previewContent');
            },
        },
    })
</script>


<style lang="css" scoped>
    .preview-title {
        font-size: 16px;
    }
    .preview-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 810px;
        min-height: 600px;
        height: auto;
        max-width: 810px;
    }
    .md-scrollbar {
        height: 600px;
        padding-bottom: 20px;
    }
    .preview-content {
        padding: 10px 15px;
    }
</style>